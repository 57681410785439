var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popPrompt wap"
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": _vm.onClose
    }
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptCenter"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popPrompt-main center popDoubleReward"
  }, [_c('div', {
    staticClass: "btn-close btn",
    on: {
      "click": _vm.onClose
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-close"
    }
  })], 1), _c('div', {
    staticClass: "popDoubleReward-head"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/new/lock/gift-1.png`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "popDoubleReward-head__p",
    style: {
      background: 'url(' + require(`@images/${_vm.theme}/new/lock/double_p.png`) + ') center/auto 100% no-repeat'
    }
  }), _c('div', {
    staticClass: "popDoubleReward-head__l"
  }, [_c('span', {
    style: {
      background: 'url(' + require(`@images/${_vm.theme}/lucky/lucky-result-light.png`) + ') center/auto 100% no-repeat'
    }
  })])]), _c('div', {
    staticClass: "popDoubleReward__title"
  }, [_vm._v("Congratulations on getting")]), _c('div', {
    staticClass: "popDoubleReward-list"
  }, [_c('div', {
    staticClass: "popDoubleReward-item"
  }, [_c('div', {
    staticClass: "popDoubleReward-item__title"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/tapcoins.png`),
      "alt": ""
    }
  }), _vm._v(" Tapcoins ")]), _c('div', {
    staticClass: "popDoubleReward-item__label"
  }, [_vm._v("20000")])]), _c('div', {
    staticClass: "popDoubleReward-item"
  }, [_c('div', {
    staticClass: "popDoubleReward-item__title"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/tap.png`),
      "alt": ""
    }
  }), _vm._v(" TAPS ")]), _c('div', {
    staticClass: "popDoubleReward-item__label"
  }, [_vm._v("20,000,000")])]), _c('div', {
    staticClass: "popDoubleReward-item"
  }, [_c('div', {
    staticClass: "popDoubleReward-item__title"
  }, [_c('img', {
    staticClass: "key",
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/key.png`),
      "alt": ""
    }
  }), _vm._v(" Keys ")]), _c('div', {
    staticClass: "popDoubleReward-item__label"
  }, [_vm._v("20,000,000")])])]), _c('div', {
    staticClass: "popDoubleReward-btns"
  }, [_c('div', {
    staticClass: "btn-go"
  }, [_vm._v(_vm._s(_vm.$lang("Double Rewards")))])]), _c('div', {
    staticClass: "popDoubleReward__need"
  }, [_vm._v("Double rewards cost: "), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/ton.png`),
      "alt": ""
    }
  }), _vm._v(" "), _c('span', [_vm._v("0.7")])])])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };