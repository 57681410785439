import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import vailcode from "@utils/errcode";
import formatter from "@utils/formatter";
export default {
  name: "popAirdripTip",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"])
  },
  data() {
    return {};
  },
  methods: {
    async onSubmit(item) {
      if (item.route.indexOf("https://") !== -1 || item.route.indexOf("http://") !== -1) {
        if (item.route.indexOf("//t.me/") !== -1) {
          this.WebApp.openTelegramLink(item.route);
        } else {
          this.WebApp.openLink(item.route);
        }
      } else if (item.route != '') {
        this.$router.push(item.route);
      }
      this.$parent.popAirdropTicketDescShow = false;
    }
  }
};