import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  props: {
    show: null
  },
  name: "popDoubleReward",
  computed: {
    ...walletVuex.mapState(["theme", "isMobile"])
  },
  watch: {
    show(bool) {
      console.log(3333);
      this.popFunc(bool, this);
    }
  },
  methods: {
    onClose() {
      this.$emit("update:show", false);
    }
  }
};