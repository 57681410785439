var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_vm.airdropTicketInfo ? _c('div', {
    staticClass: "airdropTap page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/task-detail-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "top-box"
  }, [_c('div', {
    staticClass: "top-box-title"
  }, [_vm.isProgOk ? [_vm.airdropTicketInfo.enabled && _vm.airdropTicketInfo.unclaimedAmount ? [_vm._v(" " + _vm._s(_vm.$lang('AIRDROP')) + " ")] : _vm.airdropTicketInfo.enabled ? [_vm._v(" " + _vm._s(_vm.$lang('I have received')) + " ")] : [_vm._v(" " + _vm._s(_vm.$lang('AIRDROP')) + " ")]] : [_vm._v(" " + _vm._s(_vm.$lang('AIRDROP')) + " ")]], 2), _c('div', {
    staticClass: "top-box-img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-pass.png`)
    }
  })]), _c('div', {
    staticClass: "top-box-txt"
  }, [_vm._v(" More airdrop-pass, more $TAPS. ")]), _c('div', {
    staticClass: "top-box-num"
  }, [_vm.isProgOk ? [_vm.airdropTicketInfo.enabled && _vm.airdropTicketInfo.unclaimedAmount ? _c('div', {
    staticClass: "w-txt"
  }, [_vm._v(" +" + _vm._s(_vm._f("toThousands")(_vm.airdropTicketInfo.unclaimedAmount)) + " ")]) : _c('div', {
    staticClass: "txt"
  }, [_vm.airdropTicketInfo.totalClaimedAmount ? _c('div', {
    on: {
      "click": function ($event) {
        return _vm.onDetails();
      }
    }
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.airdropTicketInfo.totalClaimedAmount || 0)))]) : _vm._e(), _c('div', {
    staticClass: "top-box-claimed"
  }, [_c('div', {
    staticClass: "top-box-claimed-level"
  }, [_c('div', {
    staticClass: "top-box-claimed-level-item active"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "top-box-claimed-level-item",
    class: (_vm.airdropTicketInfo.claimRankText == 'Medium' || _vm.airdropTicketInfo.claimRankText == 'High') && 'active'
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "top-box-claimed-level-item",
    class: _vm.airdropTicketInfo.claimRankText == 'High' && 'active'
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })])]), _c('div', {
    staticClass: "tip",
    on: {
      "click": _vm.showLevelTip
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-quest"
    }
  })], 1)]), _c('div', {
    staticClass: "next-txt"
  }, [_vm._v(" Next collection: " + _vm._s(_vm.getNextDate()) + " ")])])] : [_vm._v(" Calculating... ")]], 2)]), _c('div', {
    staticClass: "btns"
  }, [_vm.isProgOk ? [_vm.airdropTicketInfo.enabled && _vm.airdropTicketInfo.unclaimedAmount ? [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onClaim();
      }
    }
  }, [_vm._v(" Claim ")])] : _vm.airdropTicketInfo.totalClaimedAmount ? void 0 : [_c('div', {
    staticClass: "btn dis"
  }, [_vm._v(" Claim ")])]] : [_vm._v("   ")]], 2), _vm.airdropTicketInfo.enabled && _vm.airdropTicketInfo.unclaimedAmount ? _c('div', {
    staticClass: "calendar-box"
  }, _vm._l(_vm.dateInfos, function (item, index) {
    return _c('div', {
      staticClass: "item",
      class: !item.date ? 'item-dblue' : item.claimed ? 'item-gray' : ''
    }, [_vm._m(0, true), _c('div', {
      staticClass: "d"
    }, [_vm._v(_vm._s(_vm._f("formatDateStr")(item.date, 'day')))]), _c('div', {
      staticClass: "m"
    }, [_vm._v(_vm._s(_vm._f("formatDateStr")(item.date, 'month')))])]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "countDown"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-icons.png`)
    }
  }), _c('div', {
    staticClass: "countDown-txt"
  }, [_vm._v("Listing: February 2025")])]), _c('div', {
    staticClass: "fs"
  }, [_c('div', {
    staticClass: "fs-tabs"
  }, [_c('div', {
    staticClass: "fs-tab",
    class: _vm.selectTab == 0 && 'active',
    on: {
      "click": function ($event) {
        return _vm.setSelectTab(0);
      }
    }
  }, [_vm._v(" Key Points ")]), _c('div', {
    staticClass: "fs-tab",
    class: _vm.selectTab == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.setSelectTab(1);
      }
    }
  }, [_vm._v(" Exchanges ")])]), _vm.selectTab == 0 ? _c('div', {
    staticClass: "fs-list"
  }, [_c('div', {
    staticClass: "fs-item",
    on: {
      "click": function ($event) {
        return _vm.onTip(0);
      }
    }
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[0].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/scan-head.jpg`)
    }
  })]), _vm._m(1), _vm.progressList[0].isOk ? _c('div', {
    staticClass: "item-extend"
  }, [_c('div', {
    staticClass: "svg"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1)]) : _vm._e()])]), _c('div', {
    staticClass: "fs-item",
    on: {
      "click": function ($event) {
        return _vm.onTip(1);
      }
    }
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[1].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon item-icon-4"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tron.png`)
    }
  })]), _vm._m(2), _vm.progressList[1].isOk ? _c('div', {
    staticClass: "item-extend"
  }, [_c('div', {
    staticClass: "svg"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1)]) : _vm._e()])]), _c('div', {
    staticClass: "fs-item",
    on: {
      "click": function ($event) {
        return _vm.onTip(2);
      }
    }
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[2].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon item-icon-2"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-icon2.png`)
    }
  })]), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Keys")]), _c('div', {
    staticClass: "item-star-bg"
  }, [_c('div', {
    staticClass: "item-star active"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "item-star",
    class: (_vm.airdropTicketInfo.keyPoints.rankText.stock == 'Medium' || _vm.airdropTicketInfo.keyPoints.rankText.stock == 'High') && 'active'
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "item-star",
    class: _vm.airdropTicketInfo.keyPoints.rankText.stock == 'High' && 'active'
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })])])]), _vm.progressList[2].isOk ? _c('div', {
    staticClass: "item-extend"
  }, [_vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.airdropTicketInfo.keyPoints.value.stock || 0, 2)) + " "), _c('div', {
    staticClass: "svg"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1)]) : _vm._e()])]), _c('div', {
    staticClass: "fs-item",
    on: {
      "click": function ($event) {
        return _vm.onTip(3);
      }
    }
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[3].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon item-icon-3"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-icon3.png`)
    }
  })]), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Friends")]), _c('div', {
    staticClass: "item-star-bg"
  }, [_c('div', {
    staticClass: "item-star active"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "item-star",
    class: (_vm.airdropTicketInfo.keyPoints.rankText.invitationCount == 'Medium' || _vm.airdropTicketInfo.keyPoints.rankText.invitationCount == 'High') && 'active'
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "item-star",
    class: _vm.airdropTicketInfo.keyPoints.rankText.invitationCount == 'High' && 'active'
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })])])]), _vm.progressList[3].isOk ? _c('div', {
    staticClass: "item-extend"
  }, [_vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.airdropTicketInfo.keyPoints.value.invitationCount || 0, 2)) + " "), _c('div', {
    staticClass: "svg"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1)]) : _vm._e()])]), _c('div', {
    staticClass: "fs-item",
    on: {
      "click": function ($event) {
        return _vm.onTip(4);
      }
    }
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[4].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tap-icon1.png`)
    }
  })]), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Profit per hour")]), _c('div', {
    staticClass: "item-star-bg"
  }, [_c('div', {
    staticClass: "item-star active"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "item-star",
    class: (_vm.airdropTicketInfo.keyPoints.rankText.pph == 'Medium' || _vm.airdropTicketInfo.keyPoints.rankText.pph == 'High') && 'active'
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "item-star",
    class: _vm.airdropTicketInfo.keyPoints.rankText.pph == 'High' && 'active'
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })])])]), _vm.progressList[4].isOk ? _c('div', {
    staticClass: "item-extend"
  }, [_vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.airdropTicketInfo.keyPoints.value.pph || 0, 2)) + " "), _c('div', {
    staticClass: "svg"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1)]) : _vm._e()])]), _c('div', {
    staticClass: "fs-item",
    on: {
      "click": function ($event) {
        return _vm.onTip(5);
      }
    }
  }, [_c('div', {
    staticClass: "bg",
    style: {
      width: _vm.progressList[5].progress + '%'
    }
  }), _c('div', {
    staticClass: "item-box"
  }, [_c('div', {
    staticClass: "item-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-tickets.png`)
    }
  })]), _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Ticket")]), _c('div', {
    staticClass: "item-star-bg"
  }, [_c('div', {
    staticClass: "item-star active"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "item-star",
    class: (_vm.airdropTicketInfo.keyPoints.rankText.appTickets == 'Medium' || _vm.airdropTicketInfo.keyPoints.rankText.appTickets == 'High') && 'active'
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "item-star",
    class: _vm.airdropTicketInfo.keyPoints.rankText.appTickets == 'High' && 'active'
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })])])]), _vm.progressList[5].isOk ? _c('div', {
    staticClass: "item-extend"
  }, [_vm._v(" " + _vm._s(_vm._f("nFormatter")(_vm.airdropTicketInfo.keyPoints.value.appTickets || 0, 2)) + " "), _c('div', {
    staticClass: "svg"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1)]) : _vm._e()])])]) : _c('div', {
    staticClass: "fs-list-exchanges"
  }, [_c('div', {
    staticClass: "fs-exchnges-top"
  }, [_vm._v(" Listing progress ")]), _c('div', {
    staticClass: "fs-item"
  }, _vm._l(_vm.exchangesList, function (item, index) {
    return _c('div', {
      staticClass: "item-box"
    }, [_c('div', {
      staticClass: "item-icon"
    }, [item.icon ? _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/${item.icon}`)
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item-label"
    }, [_vm._v(_vm._s(item.name))])]), _c('div', {
      staticClass: "item-extend"
    }, [_c('div', {
      staticClass: "line-bg"
    }), _c('div', {
      staticClass: "line-progress",
      style: {
        width: item.progress + '%'
      }
    }, [_vm._v(" " + _vm._s(item.progress) + "% ")])])]);
  }), 0)])])]) : _vm._e(), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1), _c('pop-airdrop-ticket-tip', {
    attrs: {
      "show": _vm.popAirdropTicketTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAirdropTicketTipShow = $event;
      }
    }
  }), _c('pop-airdrop-ticket-logs', {
    attrs: {
      "show": _vm.popAirdropTicketLogsShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAirdropTicketLogsShow = $event;
      }
    }
  }), _c('pop-airdrop-ticket-desc', {
    attrs: {
      "show": _vm.popAirdropTicketDescShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAirdropTicketDescShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/icons/tap.png"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Anti-bot verification")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item-info"
  }, [_c('div', {
    staticClass: "item-label"
  }, [_vm._v("Make TON transaction")])]);
}];
export { render, staticRenderFns };