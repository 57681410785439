import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const mineVuex = createNamespacedHelpers("mine");
import { coinAnimFn } from "@utils/index";
import { address, toNano } from "@ton/core";
import svgIcon from '../../../advapp/src/components/svgIcon.vue';
export default {
  components: {
    svgIcon
  },
  name: "popEnergy",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "tonConnect"]),
    ...mineVuex.mapState(["lastUpgradeTime"])
  },
  watch: {
    taskInfo: {
      async handler(newVal, oldVal) {
        if (newVal != oldVal) {
          console.log(this.countdown);
          this.upgradeType = 0;
          this.isCheck = false;
          if (this.lastUpgradeTime) {
            let time = parseInt(new Date().getTime() / 1000);
            if (this.countdown > 0) {} else {
              this.countdown = Number(this.lastUpgradeTime) + this.countdownMax - time;
              if (this.countdown < 0) {
                this.isFlag = true;
                this.countdown = 0;
              } else if (this.countdown > 0 && this.countdown <= this.countdownMax) {
                this.isFlag = false;
                await this.doCountDown();
              } else {
                this.isFlag = true;
              }
            }
          } else {
            this.isFlag = true;
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  props: {
    taskInfo: {
      type: Object,
      default: () => {}
    },
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isFlag: true,
      upgradeType: 0,
      isCheck: false,
      timer: null,
      countdown: 0,
      countdownMax: 5,
      timer: null
    };
  },
  methods: {
    ...mineVuex.mapActions(["mineUpgrade", "mine"]),
    ...mineVuex.mapMutations(["setLastUpgradeTime"]),
    async onSubmit() {
      if (!this.isFlag) {
        return;
      }
      this.isFlag = false;
      if (this.upgradeType == 0) {
        this.onUpgrade();
      } else {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        if (!this.tonConnect) {
          this.$nextTick(() => {
            this.isFlag = true;
            let res = this.tonWallet.openOrCloseTonModal(true);
            this.tonWallet.ton.onStatusChange(async walletAndwalletInfo => {
              if (walletAndwalletInfo) {
                this.isFlag = false;
                this.onUpgrade('tonpay', this.tonWallet.uiAddress);
              }
            });
          });
        } else {
          if (this.isCheck) {
            this.timer = setTimeout(() => {
              this.onUpgrade('tonpay', this.tonWallet.uiAddress);
            }, 20000);
          } else {
            this.onUpgrade('tonpay', this.tonWallet.uiAddress);
          }
        }
      }
    },
    async onUpgrade(payType = 'coinpay', address = '') {
      await this.mineUpgrade([this.taskInfo.id, payType, address, this.isCheck ? 1 : 0, async rs => {
        if (rs.lucky_coin && rs.lucky_coin > 0) {
          this.$parent.doSpecialsMine();
          this.$parent.awardCoin = rs.lucky_coin;
          this.$parent.popAwardShow = true;
        }
        if (rs.mine) {
          this.isCheck = false;
          this.countdown = this.countdownMax;
          let time = parseInt(new Date().getTime() / 1000);
          this.setLastUpgradeTime(time);
          this.$nextTick(() => {
            coinAnimFn({
              containerRef: document.getElementById("destoryCoinContainerRef"),
              ref: document.getElementById("coinRef"),
              img: require(`@images/${this.theme}/lucky/tapcoins.png`),
              radius: 300,
              numCoins: 50,
              callback: () => {}
            }, this);
          });
          if (rs.task_info) {
            this.$parent.taskInfo = rs.task_info;
          }
          this.$parent.refreshData();
          await this.doCountDown();
          this.isFlag = true;
        } else {
          if (rs.chainInfo) {
            this.tonWallet.sendTransaction([{
              address: rs.chainInfo.contract,
              amount: toNano(rs.chainInfo.amount).toString(),
              payload: rs.chainInfo.raw
            }]);
            this.isCheck = true;
            if (this.timer) {
              clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
              this.isFlag = true;
            }, 20000);
          }
        }
      }, res => {
        this.countdown = 0;
        if (res && res.code == 302) {
          this.upgradeType = 1;
          if (!this.isCheck) {
            this.isCheck = true;
            this.isFlag = false;
            setTimeout(() => {
              this.isFlag = true;
            }, 20000);
          } else {
            this.isFlag = true;
          }
        } else {
          this.isCheck = false;
          this.isFlag = true;
        }
      }]);
    },
    async doCountDown() {
      if (this.countdown > 0) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.countdown--;
        if (this.countdown > 0) {
          await this.doCountDown();
        } else {
          this.isFlag = true;
        }
      }
    },
    onSelectType(type) {
      this.upgradeType = type;
    }
  }
};