import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const walletVuex = createNamespacedHelpers("wallet");
const assetsVuex = createNamespacedHelpers("assets");
import loadConfig from "@Web3WalletConfig/config";
import { toNano } from "@ton/core";
export default {
  name: "popAirdropTicketLogs",
  props: {},
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "tonConnect"]),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      page: 1,
      hasMore: true,
      isLoading: false,
      list: []
    };
  },
  watch: {
    "$parent.popAirdropTicketLogsShow": {
      handler(bool) {
        if (bool) {
          this.isLoading = false;
          this.page = 1;
          this.onLoadData();
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {},
  methods: {
    ...userVuex.mapMutations(['setUserData']),
    cancelFn() {
      this.$emit("cancel");
    },
    checkScroll() {
      const scrollHeight = this.$refs.myScroll.scrollHeight;
      const scrollTop = this.$refs.myScroll.scrollTop;
      const clientHeight = this.$refs.myScroll.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        // 接近底部时触发加载  
        if (this.hasMore && !this.isLoading) {
          this.page += 1;
          this.onLoadData();
        }
      }
    },
    async onLoadData() {
      this.isLoading = true;
      let rs = await this.$http.post("airdrop/ticket/claim/logs", {
        page: this.page
      });
      this.isLoading = false;
      if (rs.code == 0) {
        this.list = rs.data.items;
        if (rs.data.lastPage > rs.data.currentPage) {
          this.hasMore = true;
        } else {
          this.hasMore = false;
        }
      }
    },
    refresh() {
      const element = this.$refs.popRefresh;
      element.style.animation = 'pop-ref 1s linear forwards';
      setTimeout(() => {
        element.style.animation = ''; // 移除动画属性  
      }, 2000);
      this.onLoadData(1);
      this.$refs.myScroll.scrollTo({
        top: 0,
        left: 0
      });
    }
  }
};