import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const bioMatrixScanVuex = createNamespacedHelpers("bioMatrixScan");
import { coinAnimFn } from "@utils/index";
import { nFormatter } from "@utils/index";
export default {
  name: "bioMatrixScanInvite",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...bioMatrixScanVuex.mapState(["itemList", "rankingList", "rankingTotal", "logList", "logHasMore", "logLoading", "logCondition"])
  },
  data() {
    return {
      currentData: {},
      rate: 0,
      selectTab: 1,
      scorllFlag: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.currentData = {};
    this.rate = 0;
    this.selectTab = 0;
    this.scorllFlag = false;
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    this.initItems();
    this.getRankingList();
    this.onLoadData(1);
  },
  methods: {
    ...bioMatrixScanVuex.mapActions(["getItemList", "getRankingList", "getLogList", "faceClaim"]),
    ...bioMatrixScanVuex.mapMutations(["setLogLoading", "setLogCondition"]),
    claim(item, index) {
      if (item.claimed || !item.reached) {
        return;
      }
      this.faceClaim([index, res => {
        this.initItems();
        this.$nextTick(() => {
          coinAnimFn({
            containerRef: document.getElementById("destoryCoinContainerRef"),
            ref: document.getElementById("coinRef"),
            img: require(`@images/${this.theme}/lucky/usdt.png`),
            radius: 300,
            numCoins: 50,
            callback: () => {}
          }, this);
        });
      }, rs => {}]);
    },
    initItems() {
      let that = this;
      let lineData = [];
      this.getItemList([res => {
        //this.lineData
        res.forEach((item, index) => {
          if (item.current) {
            that.currentData = item;
            that.rate = parseInt(that.currentData.value / that.currentData.threshold * 100);
          }
        });
      }, () => {}]);
    },
    onLoadData(page) {
      this.setLogCondition({
        "limit": 10,
        "page": page
      });
      this.getLogList([() => {
        this.scorllFlag = false;
      }, () => {
        this.scorllFlag = false;
      }]);
    },
    onShare() {
      let shareData = `TapCoins'%20%24TAPS%20will%20soon%20be%20listing%20on%20a%20well-known%20exchange.%20Please%20complete%20the%20anti-bot%20verification%20first%2C%20and%20you%20can%20get%20a%201000TAPS%20reward`;
      this.WebApp.openTelegramLink(`https://t.me/share/url?text=${shareData}&url=https://t.me/${this.setting.botname}/app?startapp=ref_${this.userData.ref_code}-page_bioMatrixScan`);
    },
    onClickTab(tab) {
      this.selectTab = tab;
    },
    checkScroll() {
      if (this.selectTab == 1) {
        const scrollHeight = this.$refs.myScroll.scrollHeight;
        const scrollTop = this.$refs.myScroll.scrollTop;
        const clientHeight = this.$refs.myScroll.clientHeight;
        if (!this.scorllFlag) {
          if (scrollTop + clientHeight >= scrollHeight - 50) {
            // 接近底部时触发加载
            this.scorllFlag = true;
            if (this.logHasMore && !this.logLoading) {
              let page = this.logCondition.page;
              this.onLoadData(page + 1);
            }
          }
        }
      }
    },
    jumpToProof() {
      this.WebApp.openLink('https://tonviewer.com/EQAebdctnt2DE6nSS3dkFHmdxHa4ml_Y8U_SShBYdGCYqj_9/jetton/EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs');
    }
  }
};