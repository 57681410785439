var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_vm.taskInfo ? _c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img",
    class: {
      enery__img__rectangle: _vm.type == 1,
      enery__img__square: _vm.type == 2
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.taskInfo.icon,
      "alt": ""
    }
  }), _vm.type == 2 ? _c('div', {
    staticClass: "soon"
  }, [_vm._v(" Free Mint "), _c('span', [_vm._v("soon")])]) : _vm._e()]), _c('div', {
    staticClass: "energy__title"
  }, [_vm._v(_vm._s(_vm.taskInfo.name))]), _c('div', {
    staticClass: "energy__subtitle"
  }, [_vm._v(" " + _vm._s(_vm.taskInfo.description) + " ")]), _c('div', {
    staticClass: "energy__lv"
  }, [_c('div', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "n"
  }, [_vm._v("lvl " + _vm._s(_vm.taskInfo.current_level))]), _c('div', {
    staticClass: "i"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/up.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "t"
  }, [_vm._v("lvl " + _vm._s(_vm.taskInfo.current_level + 1))])])]), _c('div', {
    staticClass: "energy__threetitle"
  }, [_c('div', [_vm._v(" Profit per hour "), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/index-icon-small-coin.png`),
      "alt": ""
    }
  }), _c('i', [_vm._v("+" + _vm._s(_vm._f("toThousands")(Number(_vm.taskInfo.upgrade_inc_earnings))))])])]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn",
    class: [_vm.upgradeType == 0 && 'active', _vm.taskInfo.upgrade_ton_cost < 0.02 && 'no-border'],
    on: {
      "click": function ($event) {
        return _vm.onSelectType(0);
      }
    }
  }, [_vm.taskInfo.upgrade_cost ? _c('div', {
    staticClass: "btn-num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/tapcoins.png`),
      "alt": ""
    }
  }), _c('div', [_vm._v(_vm._s(_vm._f("toThousands")(_vm.taskInfo.upgrade_cost)))])]) : _vm._e()]), _vm.taskInfo.upgrade_ton_cost >= 0.02 ? _c('div', {
    staticClass: "btn",
    class: _vm.upgradeType == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onSelectType(1);
      }
    }
  }, [_c('div', {
    staticClass: "btn-num"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/ton.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toThousands")(_vm.taskInfo.upgrade_ton_cost)) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "update-box"
  }, [_c('div', {
    staticClass: "update-btn",
    class: !_vm.isFlag && 'disable',
    on: {
      "click": _vm.onSubmit
    }
  }, [!_vm.isCheck ? [_vm._v(" Upgrade"), _vm.countdown > 0 ? [_vm._v("(" + _vm._s(_vm.countdown) + "s)")] : _vm._e()] : [_vm.isFlag ? [_vm._v(" Check ")] : _vm._e(), !_vm.isFlag ? [_vm._v(" Checking ")] : _vm._e()]], 2), _vm.isCheck && !_vm.isFlag ? _c('div', {
    staticClass: "r-loading"
  }, [_c('round-loading')], 1) : _vm._e()])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };