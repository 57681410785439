var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "appcenter page",
    class: _vm.isMobile && 'wap',
    attrs: {
      "id": "appcenter"
    }
  }, [_c('div', {
    staticClass: "appcenter-main"
  }, [_c('div', {
    staticClass: "adv-box"
  }, [_c('Carousel', {
    attrs: {
      "images": _vm.images,
      "height": 135
    }
  })], 1), _c('div', {
    staticClass: "center-box"
  }, [_c('div', {
    staticClass: "center-box-title"
  }, [_vm._v(" Center Tickets ")]), _c('div', {
    staticClass: "center-box-tickets"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/tickets.png`)
    }
  }), _vm._v(" Tickets:"), _c('span', [_vm._v(_vm._s(_vm.userData.app_center_tickets))]), _c('i', {
    on: {
      "click": _vm.showTip
    }
  }, [_vm._v("?")])])]), _c('div', {
    staticClass: "tab_main"
  }, [_c('ul', _vm._l(_vm.categoryList, function (item, index) {
    return _c('li', {
      staticClass: "tab_item",
      class: _vm.selectTab == item.id ? 'checked' : '',
      on: {
        "click": function ($event) {
          return _vm.click_tab(item);
        }
      }
    }, [_vm._v(_vm._s(_vm.$lang(item.name)))]);
  }), 0)]), _c('div', {
    staticClass: "boo"
  }, [_vm.taskList[_vm.selectTab] ? _c('div', {
    staticClass: "boo-list"
  }, _vm._l(_vm.taskList[_vm.selectTab], function (item, index) {
    return _c('div', {
      staticClass: "boo-item",
      class: item.status == 0 && 'mask',
      on: {
        "click": function ($event) {
          return _vm.onBoo(item);
        }
      }
    }, [_c('div', {
      staticClass: "boo-icon",
      class: {
        'boo-icon-square': _vm.selectCategory.icon_type == 1
      }
    }, [_c('img', {
      attrs: {
        "src": item.icon,
        "alt": ""
      }
    }), item.title == 'CryptoQuest' ? _c('div', {
      staticClass: "new",
      style: {
        background: `url(${require(`@images/${_vm.theme}/new/new.png`)}) center/auto 100% no-repeat`
      }
    }) : _vm._e()]), item.type == 'miniapp' ? _c('div', {
      staticClass: "boo-info"
    }, [_c('div', {
      staticClass: "boo-cost",
      class: item.current_level == 0 ? 'gray-icon' : ''
    }, [_vm._v(" Profit per hour "), _c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/airdrop-tap-icon6.png`),
        "alt": ""
      }
    }), _c('span', [_vm._v(" +" + _vm._s(_vm._f("nFormatter")(item.reward_pph, 2)) + " ")])]), _c('div', {
      staticClass: "boo__num"
    }, [_c('div', {
      staticClass: "boo__num__coin"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/tickets.png`),
        "alt": ""
      }
    }), _vm._v(_vm._s(item.completion_count)), _c('span', [_vm._v("/" + _vm._s(item.limit_count))])])])]) : _vm._e()]);
  }), 0) : _vm._e()])]), _c('pop-appcenter-energy', {
    attrs: {
      "show": _vm.popEnergyShow,
      "taskInfo": _vm.taskInfo
    },
    on: {
      "update:show": function ($event) {
        _vm.popEnergyShow = $event;
      }
    }
  }), _c('pop-appcenter-tip-energy', {
    attrs: {
      "show": _vm.popEngrayTipShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popEngrayTipShow = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };