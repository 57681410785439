var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_vm.$parent.tipData.title ? _c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img"
  }, [_c('img', {
    attrs: {
      "src": _vm.$parent.tipData.icon,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "energy__title"
  }, [_vm._v(" " + _vm._s(_vm.$parent.tipData.title) + " ")]), _c('div', {
    staticClass: "energy__subtitle"
  }, [_vm._v(" " + _vm._s(_vm.$parent.tipData.brief) + " ")]), _c('div', {
    staticClass: "energy__txt"
  }, [_vm._v(" " + _vm._s(_vm.$parent.tipData.txt) + " ")]), _vm.$parent.tipData.star > 0 ? _c('div', {
    staticClass: "energy__star"
  }, [_c('div', {
    staticClass: "item-star active"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "item-star",
    class: _vm.$parent.tipData.star > 1 && 'active'
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "item-star",
    class: _vm.$parent.tipData.star > 2 && 'active'
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })])]) : _vm._e(), _vm.$parent.tipData.star > 0 ? _c('div', {
    staticClass: "energy__star__txt"
  }, [_vm.$parent.tipData.star == 1 ? [_vm._v(" Your stats are lower than most players. Focus on improving your metrics to secure an airdrop. ")] : _vm._e(), _vm.$parent.tipData.star == 2 ? [_vm._v(" Your values ​​are at a medium level. Please continue to improve your indicators to get a larger share of the airdrop. ")] : _vm._e(), _vm.$parent.tipData.star == 3 ? [_vm._v(" Your stats are ahead of most players, keep improving to stay ahead and maximize your rewards. ")] : _vm._e()], 2) : _vm._e(), _c('div', {
    staticClass: "btns"
  }, _vm._l(_vm.$parent.tipData.btns, function (item, index) {
    return _c('div', {
      staticClass: "btn",
      class: item.is_gray && 'gray',
      on: {
        "click": function ($event) {
          return _vm.onSubmit(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " "), item.is_gray ? _c('div', {
      staticClass: "svg-ok"
    }, [_c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    })], 1) : _vm._e()]);
  }), 0)]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };