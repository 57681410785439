import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "mMenu",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "taskCount"]),
    route() {
      return this.$route.path;
    }
  },
  data() {
    const menu = [{
      label: "HOME",
      icon: "menu-home",
      path: "/"
    }, {
      label: "TASKS",
      icon: "menu-tasks",
      path: "/earn"
    }, {
      label: "Lab",
      icon: "menu-app",
      path: "/appcenter"
    }, {
      label: "STUDY",
      icon: "menu-study",
      path: "/mine"
    }, {
      label: "AIRDROP",
      icon: "menu-airdrop",
      path: "/airdropSecond"
    }];
    return {
      menu
    };
  },
  methods: {
    onSkip(page, id) {
      this.$router.push(page);
    }
  },
  mounted() {
    this.setWowAnim();
    //this.loadTaskCount();
  }
};