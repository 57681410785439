// src/Button.js
import React, { useEffect } from 'react'
import '../index.css'
import { GetTonAd, OnAdClick, SendTonAdEvent } from '../../utils/my_util'
import { OnAdVerifyOptions, TonAdProps } from '../../utils/models'

export const TonAdPopup = ({
  tonAd,
  blockId,
  countdown = 15,
  autoClose = true,
  onAdShow,
  onAdClick,
  onAdComplete,
  onAdClose,
  onAdError,
  onAdVerifyed
}: {
  tonAd?: TonAdProps
  blockId?: string
  countdown?: number
  autoClose?: boolean
  onAdClick?: (ad: TonAdProps) => void
  onAdComplete?: (ad: TonAdProps) => void
  onAdClose?: (ad: TonAdProps) => void
  onAdShow?: (ad: TonAdProps) => void
  onAdError?: (error: any) => void
  onAdVerifyed?: (options: OnAdVerifyOptions) => void
}) => {
  if (countdown < 10 || countdown > 30) {
    countdown = 15
    throw new Error('Countdown must be between 10 and 30')
  }
  const [timecount, setTimecount] = React.useState(-1)
  const [startCount, setStartCount] = React.useState(false)
  const [ad, setAd] = React.useState<TonAdProps>(tonAd)
  const timerRef = React.useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (!ad) return
    onAdShow?.(ad)
    setStartCount(true)
    setTimecount(countdown)
  }, [ad])

  useEffect(() => {
    if (!startCount) return

    // 立即上报一次
    SendTonAdEvent(
      'impression',
      { ...ad, num: 1 },
      () => {},
      (e) => {
        onAdError?.(e || 'Post event error')
        console.error('postEvent', e)
      }
    )

    timerRef.current = setInterval(() => {
      setTimecount((prev) => {
        // 如果已经点击或关闭,停止计时
        if (!ad) {
          if (timerRef.current) {
            clearInterval(timerRef.current)
          }
          return 0
        }

        if (prev <= 1) {
          if (timerRef.current) {
            clearInterval(timerRef.current)
          }
          onAdComplete?.(ad)
          SendTonAdEvent('complete', ad)
          if (autoClose) {
            closeAd(1000)
          }
          return 0
        }

        if ((countdown - prev - 1) % 2 === 0) {
          // 每2秒上报一次
          SendTonAdEvent(
            'impression',
            { ...ad, num: (countdown - prev - 1) / 2 + 2 },
            () => {
              // console.log('impression, num=', (countdown - prev - 1) / 2 + 1)
            },
            (e) => {
              onAdError?.(e || 'Post event error')
              console.error('postEvent', e)
            }
          )
        }
        return prev - 1
      })
    }, 1000)
    // 组件卸载时清理
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [startCount])

  const closeAd = (timeout = 2000) => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
    setTimeout(() => {
      setAd(null)
      onAdClose?.(ad)
    }, timeout)
  }

  const getAd = async (blockId: string) => {
    try {
      const data = await GetTonAd(blockId)
      // console.log('fetch banner ad', data)
      if (data.success) {
        setAd({
          adId: data?.data?.adId,
          adBlockId: data?.data?.adBlockId,
          campaignId: data?.data?.campaignId,
          image: data?.data?.image,
          text: data?.data?.text,
          destination: data?.data?.destination,
          icon: data?.data?.icon,
          popupImage: data?.data?.popupImage,
          brandName: data?.data?.brandName,
          buttonText: data?.data?.buttonText,
          adFormat: data?.data?.adFormat
        })
      } else {
        onAdError?.(data.message || 'Fetch Ad response error')
      }
    } catch (error) {
      onAdError?.(error || 'Fetch Ad catch error')
      console.error('fetch ad', error)
    }
  }

  useEffect(() => {
    if (blockId) {
      getAd(blockId)
    }
  }, [blockId])

  const clickAd = (ad: TonAdProps) => {
    onAdClick?.(ad)
    setTimeout(() => {
      OnAdClick(ad, onAdVerifyed)
    }, 100)
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
  }

  return (
    <div
      className='bg-[#ada2d4] absolute'
      style={{
        display: ad ? 'flex' : 'none',
        // opacity: ad ? 1 : 0,
        height: '100vh',
        width: '100vw',
        minWidth: '360px',
        minHeight: '550px'
      }}
    >
      {ad ? (
        <div
          // id='ad-popup'
          // className={`flex flex-col w-full h-full`}
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '10px',
            backgroundImage: 'url(https://static.pea.ai/img/tg_bg2.jpg)',
            backgroundSize: 'cover',
            backgroundColor: '#ada2d4',
            // backgroundColor: '#f1f1f1',
            backgroundRepeat: 'no-repeat'
          }}
        >
          {/* ad header */}
          <div
            // className='w-full flex flex-row items-center justify-between px-4 py-2'
            style={{
              // position: 'fixed',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            {/* close button */}
            <div
              // className='  fixed left-0 top-0 m-2 px-2 py-1 flex flex-row items-center justify-center rounded bg-black bg-opacity-20 min-w-12 cursor-pointer hover:bg-opacity-30'
              onClick={() => {
                closeAd(0)
              }}
              style={{
                // position: 'fixed',
                // margin: '0.5rem',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '5px',
                paddingBottom: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '0.25rem',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                minWidth: '3rem',
                cursor: 'pointer'
              }}
            >
              <span
                className='text-white text-xs w-full text-center'
                style={{
                  color: 'white',
                  fontSize: '12px',
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                {'Close'}
              </span>
            </div>

            {/* countdown */}
            <div
              // className=' fixed right-0 top-0 m-2 px-2 py-1 flex flex-row items-center justify-center rounded bg-black bg-opacity-20 min-w-12 cursor-pointer hover:bg-opacity-30'
              style={{
                // position: 'fixed',
                // margin: '0.5rem',
                visibility: timecount <= 0 ? 'hidden' : 'visible',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: '5px',
                paddingBottom: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '0.25rem',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                minWidth: '3rem',
                cursor: 'pointer'
              }}
            >
              <span
                // className='text-white text-sm w-full font-medium text-center'
                style={{
                  color: 'white',
                  fontSize: '12px',
                  width: '100%',
                  textAlign: 'center'
                }}
              >{`${timecount}s`}</span>
            </div>
          </div>
          <div
            // className='w-full h-full flex flex-col px-2 justify-between bg-[#ada2d4]'
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              flex: 1,
              overflowY: 'auto',
              // paddingTop: '30px',
              // marginTop: '50px',
              justifyContent: 'space-between'
            }}
            onClick={() => {
              clickAd(ad)
            }}
          >
            {/* ad avatar */}
            <div
              // className='w-full flex flex-row gap-0 items-end mt-10 pt-2'
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '0px',
                alignItems: 'end',
                marginTop: '10px',
                paddingTop: '2px'
              }}
            >
              <img
                // className=' rounded-full bg-slate-200 object-cover object-center '
                style={{
                  borderRadius: '50%',
                  backgroundColor: '#e0e0e0',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  width: '42px',
                  height: '42px',
                  marginBottom: '104px',
                  imageRendering: 'auto' // 添加 imageRendering 属性以支持 GIF 动画
                }}
                src={ad.icon || '/icon/no_avatar2.svg'}
                alt='ad icon'
                loading='eager'
              ></img>

              <div
                // className='flex flex-col gap-2'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px'
                }}
              >
                <div
                  // className='flex flex-row items-end'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'end'
                  }}
                >
                  <svg width='8' height='8' className='transform'>
                    <polygon points='8,8 0,8 8,0' fill='white' />
                  </svg>
                  <div
                    style={{
                      maxWidth: '292px',
                      borderWidth: '2px',
                      backgroundColor: 'white',
                      borderColor: 'white',
                      borderStyle: 'solid',
                      padding: '3px',
                      minWidth: '240px',
                      borderTopLeftRadius: '16px',
                      borderTopRightRadius: '16px',
                      borderBottomRightRadius: '16px',
                      overflow: 'clip'
                    }}
                  >
                    {(ad.popupImage || ad.image) && (
                      <img
                        // className=' bg-slate-200 object-cover object-center rounded-t-xl'
                        src={ad.popupImage || ad.image}
                        alt='ad image'
                        style={{
                          borderTopLeftRadius: 16,
                          borderTopRightRadius: 16,
                          backgroundColor: '#e0e0e0',
                          objectFit: 'cover',
                          objectPosition: 'center',
                          width: 290,
                          height: 290,
                          imageRendering: 'auto' // 添加 imageRendering 属性以支持 GIF 动画
                        }}
                        loading='eager'
                      ></img>
                    )}
                    <div
                      // className='w-full flex flex-col p-1 gap-3'
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '5px',
                        gap: '12px'
                      }}
                    >
                      {/* <span className=' whitespace-pre-wrap text-sm font-medium'>
                      {ad.buttonText}
                    </span> */}
                      <span
                        // className=' whitespace-pre-wrap text-sm'
                        style={{
                          width: '100%',
                          whiteSpace: 'pre-wrap',
                          color: '#000',
                          fontSize: '15px'
                        }}
                      >
                        {ad.text}
                      </span>
                      <div
                        // className='w-full flex flex-row justify-between items-baseline'
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'baseline'
                        }}
                      >
                        <span
                          // className='text-sm text-gray-400'
                          style={{
                            fontSize: '14px',
                            color: '#808080'
                          }}
                        >
                          {ad.brandName || 'Advertiser'}
                        </span>
                        <span
                          // className='text-xs text-gray-400 opacity-80'
                          style={{
                            fontSize: '12px',
                            color: '#808080',
                            opacity: 0.8
                          }}
                        >
                          {'Ad·18+'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  // className='w-full pl-2 flex flex-col gap-2'
                  style={{
                    width: '100%',
                    paddingLeft: '2px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                  }}
                >
                  <div
                    // className={`${timecount <= 9 && timecount >= 0 ? '' : 'invisible'} w-full flex flex-col items-center justify-center h-8 rounded-t-md rounded-b-xl bg-black bg-opacity-20 cursor-pointer hover:bg-opacity-30`}
                    style={{
                      visibility:
                        countdown - timecount >= 1 && timecount >= 0
                          ? 'visible'
                          : 'hidden',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '14px',
                      height: '36px',
                      // borderRadius: 8,
                      borderRadius: '0.5rem',
                      color: 'white',
                      // backgroundColor: 'rgba(117, 117, 117, 0.5)',
                      backgroundColor: '#0066FF',
                      cursor: 'pointer'
                    }}
                  >
                    {ad.buttonText || 'Go'}
                  </div>
                  <div
                    // className={`${timecount <= 7 && timecount >= 0 ? '' : 'invisible'} w-full flex flex-row justify-center items-center`}
                    style={{
                      height: '50px',
                      visibility:
                        countdown - timecount >= 2 && timecount >= 0
                          ? 'visible'
                          : 'hidden',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      style={{
                        width: 'auto',
                        position: 'relative',
                        backgroundColor: '#fff',
                        borderRadius: '50px',
                        padding: '5px 5px',
                        display: 'inline-block',
                        color: '#000',
                        textAlign: 'center',
                        minWidth: '50px'
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute' as 'absolute',
                          top: '-5px',
                          left: 'calc(50% - 5px)',
                          width: '0',
                          height: '0',
                          borderLeft: '5px solid transparent',
                          borderRight: '5px solid transparent',
                          borderBottom: '5px solid #fff'
                        }}
                      ></div>
                      <span
                        style={{ fontSize: '12px', width: 'auto' }}
                        className='px-2'
                      >
                        Click to get the reward!
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ad footer */}
            <div
              // className='w-full flex flex-col justify-end items-center '
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'end',
                alignItems: 'center'
              }}
            >
              <span
                // className='text-white mb-10 text-xs bg-black bg-opacity-20 px-2 py-1 rounded-full'
                style={{
                  color: 'white',
                  marginBottom: '10px',
                  fontSize: '12px',
                  backgroundColor: 'rgba(117, 117, 117, 0.5)',
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                  paddingTop: '0.25rem',
                  paddingBottom: '0.25rem',
                  borderRadius: '1rem'
                }}
              >
                @Ton.AI - {'ads in Telegram'}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default TonAdPopup
