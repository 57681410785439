import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const luckyBoxVuex = createNamespacedHelpers("luckybox");
import vailcode from "@utils/errcode";
export default {
  name: "quest",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...luckyBoxVuex.mapState(["luckyBoxList", "selectTab", "luckyBoxInfo"])
  },
  data() {
    return {
      itemData: {},
      selectItemIndex: -1,
      isFlag: true,
      popTipShow: false,
      popRankingShow: false,
      popRecordsShow: false,
      popRecordsInfoShow: false,
      logId: 0,
      countDown: 0,
      countDownTime: null,
      roundStatusTime: null,
      isPopRewardResultShow: false,
      selectRow: {},
      lastBuyTime: 0
    };
  },
  watch: {
    "selectTab": {
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal >= 0) {
          clearTimeout(this.countDownTime);
          this.getItemData();
          this.getLuckyBoxInfo(this.itemData.id);
        }
      },
      deep: true,
      immediate: false
    },
    "luckyBoxInfo": {
      handler(newVal, oldVal) {
        if (newVal.id) {
          this.countDown = this.luckyBoxInfo.round.countdown || 0;
          this.countDownLeft();
        }
      },
      deep: true,
      immediate: false
    }
  },
  beforeRouteLeave(to, from, next) {
    this.selectItemIndex = -1;
    this.isFlag = true;
    this.popTipShow = false;
    this.popRankingShow = false;
    this.popRecordsShow = false;
    this.popRecordsInfoShow = false;
    this.isPopRewardResultShow = false;
    this.$loading.hide();
    clearTimeout(this.countDownTime);
    clearTimeout(this.roundStatusTime);
    this.$nextTick(() => {
      next();
    });
  },
  methods: {
    ...luckyBoxVuex.mapActions(["getLuckyBoxList", "getLuckyBoxInfo"]),
    ...luckyBoxVuex.mapMutations(['setSelectTab', 'setLuckyBoxInfo']),
    jumpTo(url) {
      this.$router.push(url);
    },
    onTap(item, index) {
      if (item.status == 0) return;
      this.selectItemIndex = -1;
      this.setSelectTab(index);
    },
    getItemData() {
      this.luckyBoxList.map((item, index) => {
        if (index == this.selectTab) {
          this.itemData = item;
        }
      });
    },
    async onBuy() {
      let time = parseInt(new Date().getTime() / 1000);
      if (this.lastBuyTime && time - 5 < Number(this.lastBuyTime)) {
        this.$toasted.error("Buy cooldown:5s");
        return;
      }
      this.lastBuyTime = time;
      if (!this.isFlag) return;
      if (this.luckyBoxInfo.round.status != 1 || this.luckyBoxInfo.max_buy_count > 0 && this.luckyBoxInfo.max_buy_count <= this.luckyBoxInfo.user.round.user_buy_count) {
        return false;
      }
      if (this.selectItemIndex == -1) {
        this.$toasted.error('Please select a box');
        return false;
      }
      this.isFlag = false;
      let itemId = this.luckyBoxInfo.items[this.selectItemIndex].id;
      this.$loading.show();
      let rs = await this.$http.post("/lucky/box/buy", {
        boxId: this.itemData.id,
        itemId,
        count: 1
      });
      setTimeout(() => {
        this.$loading.hide();
        this.isFlag = true;
        this.selectItemIndex = -1;
        vailcode(rs, () => {
          this.setLuckyBoxInfo(rs.data);
        });
      }, 5000);
    },
    onRanking() {
      this.popRankingShow = true;
    },
    onRecords() {
      this.popRecordsShow = true;
    },
    onAccept() {
      this.isPopRewardResultShow = false;
    },
    countDownLeft() {
      clearTimeout(this.countDownTime);
      if (this.countDown > 0) {
        this.countDownTime = setTimeout(() => {
          this.countDown = this.countDown - 1;
          console.log('this.countDown', this.countDown);
          this.countDownLeft();
          if (this.countDown % 5 == 0 || this.countDown == 0) {
            if (this.luckyBoxInfo.round.status == 2) {
              this.checkRoundStatus(this.luckyBoxInfo.round.id);
            }
            if (this.countDown == 0) this.getLuckyBoxInfo(this.itemData.id);
          }
        }, 1000);
      }
    },
    async checkRoundStatus(roundId) {
      clearTimeout(this.roundStatusTime);
      let rs = await this.$http.post('/lucky/box/winning/status', {
        roundId
      });
      if (rs.code == 0 && rs.data.status == 1) {
        if (rs.data.winning_amount > 0) {
          this.selectRow = {
            value: rs.data.winning_amount.toFixed(2),
            unit: this.itemData.currency,
            type: this.itemData.currency.toLowerCase()
          };
          if (this.selectRow.unit == 'TAP') {
            this.selectRow.unit = 'TAPS';
          }
          this.isPopRewardResultShow = true;
          await this.getLuckyBoxInfo(this.itemData.id);
        }
      } else {
        this.roundStatusTime = setTimeout(() => {
          this.checkRoundStatus(roundId);
        }, 2000);
      }
    },
    toDeposit() {
      this.$router.push('/depositDetail?cid=ton&id=2');
    }
  },
  async mounted() {
    await this.getLuckyBoxList();
    if (this.luckyBoxList.length > 0) {
      this.getItemData();
      await this.getLuckyBoxInfo(this.itemData.id);
      if (this.luckyBoxInfo.id) {
        this.countDown = this.luckyBoxInfo.round.countdown || 0;
        this.countDownLeft();
      }
    }
  }
};