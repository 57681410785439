import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    airdropList: [],
    airdropInfo: {},
    airdropInvitation:[],
    airdropInfoTime:[],
    airdropInvitationTime:[],
    airdropTapInfo:null,
    airdropTicketInfo:null
  },
  mutations: {
    setAirdropList: (state, payload) => {
      state.airdropList = payload;
    },
    setAirdropInfo: (state, payload) => {
        state.airdropInfo = payload;
    },
    setAirdropInvitation: (state, payload) => {
      state.airdropInvitation = payload;
    },
    setAirdropTapInfo: (state, payload) => {
      state.airdropTapInfo = payload;
    },
    setAirdropTicketInfo: (state, payload) => {
      state.airdropTicketInfo = payload;
    },
  },
  actions: {
    getAirdropList:async ({ state, commit }) => {
        const rs = await Vue.prototype.$http.post("/airdrop/list",{});
        vailcode(rs, () => {
          commit("setAirdropList", rs.data);
        });
    },
    getAirdropInfo:async ({ state, commit },[id,callback,failcallback]) => {
      let time = parseInt(new Date().getTime() / 1000)
      if((state.airdropInfo.id || 0 ) !=id || time - 10 > (state.airdropInfoTime[id] || 0)){
        const rs = await Vue.prototype.$http.post("/airdrop/info",{id});
        vailcode(rs, () => {
            commit("setAirdropInfo", rs.data);
            state.airdropInfoTime[id] = parseInt(new Date().getTime() / 1000)
            typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        });
      }
    },
    getAirdropInvitationList:async ({ state, commit },[airdropId,limit=100,page=1]) => {
        let time = parseInt(new Date().getTime() / 1000)
        if(time - 10 > (state.airdropInvitationTime[airdropId] || 0)){
          const rs = await Vue.prototype.$http.post("/airdrop/invitation/rewards",{airdropId,limit,page});
          vailcode(rs, () => {
            state.airdropInvitationTime[airdropId] = parseInt(new Date().getTime() / 1000)
            commit("setAirdropInvitation", rs.data);
          });
        }
    },
    getAirdropTapInfo:async ({ state, commit },callback)=>{
        const rs = await Vue.prototype.$http.post("/airdrop/tap/current/info",{});
        vailcode(rs, () => {
          commit("setAirdropTapInfo", rs.data);
          typeof callback=='function' && callback(rs.data)
        });
    },
    getAirdropTickeInfo:async ({ state, commit },callback)=>{
      const rs = await Vue.prototype.$http.post("/airdrop/ticket/current/info",{});
      vailcode(rs, () => {
        commit("setAirdropTicketInfo", rs.data);
        typeof callback=='function' && callback(rs.data)
      });
  },
  },
  getters: {},
};
