import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "popLockAvatar",
  computed: {
    ...walletVuex.mapState(["theme"])
  },
  data() {
    return {
      selectGift: 0,
      isLoading: null
    };
  },
  methods: {
    onSelectGift(_, index) {
      this.selectGift = index;
    },
    onAhead() {
      console.log(6666);
      this.$emit("aheadFn", this.selectGift);
    }
  }
};