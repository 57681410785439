var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "depositDetail page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/task-detail-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "top-title"
  }, [_vm._v("Quantity")]), _c('div', {
    staticClass: "top-box"
  }, _vm._l(_vm.moneyList, function (item) {
    return _c('div', {
      staticClass: "top-box-item",
      class: _vm.depositAmount == item && 'active',
      on: {
        "click": function ($event) {
          return _vm.doSelectMoney(item);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": _vm.assetsInfo.icon
      }
    }), _vm._v(" " + _vm._s(item) + " ")]);
  }), 0), _c('div', {
    staticClass: "top-btn",
    on: {
      "click": _vm.confirmFn
    }
  }, [_vm._v(" Deposit ")])]), _c('div', {
    staticClass: "log-header"
  }, [_vm._v(" Record "), _c('div', {
    ref: "refRefresh",
    staticClass: "refresh",
    on: {
      "click": _vm.refresh
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-refresh"
    }
  })], 1)]), _vm.depositLog.length > 0 ? _c('div', {
    staticClass: "log-list"
  }, [_c('ul', {
    staticClass: "log-box"
  }, _vm._l(_vm.depositLog, function (item) {
    return _c('li', {
      staticClass: "log-item"
    }, [_c('div', {
      staticClass: "log-top"
    }, [_c('div', {
      staticClass: "log-title"
    }, [_c('img', {
      attrs: {
        "src": _vm.assetsInfo.icon
      }
    }), _c('div', [_vm._v(_vm._s(item.amount))])]), _c('div', {
      staticClass: "log-address"
    }, [_vm._v(" " + _vm._s(_vm._f("fmt_address")(item.from_address, 10)) + " ")])]), item.status == 0 || item.status == 1 ? _c('div', {
      staticClass: "log-waiting"
    }, [_c('div', {
      staticClass: "log-waiting-txt"
    }, [_vm._v("Confiming,Please wait")])]) : _vm._e(), item.status == -1 ? _c('div', {
      staticClass: "log-waiting",
      class: `log-waiting_${item.status}`
    }, [_vm._v(" Payment timeout ")]) : _vm._e(), item.status == 2 ? _c('div', {
      staticClass: "log-waiting",
      class: `log-waiting_${item.status}`
    }, [_c('div', {
      staticClass: "log-success"
    }, [_c('svg-icon', {
      attrs: {
        "name": "icon-ok"
      }
    })], 1), _vm._v(" Payment successful ")]) : _vm._e()]);
  }), 0)]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };