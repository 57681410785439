import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const appcenterVuex = createNamespacedHelpers("appcenter");
export default {
  name: "appcenter",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...appcenterVuex.mapState(["categoryList", "taskList", 'selectTab', 'advList', "taskInfo"])
  },
  data() {
    return {
      images: [],
      popEnergyShow: false,
      popEngrayTipShow: false,
      selectCategory: {}
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popEnergyShow = false;
    this.popEngrayTipShow = false;
    this.$nextTick(() => {
      next();
    });
  },
  async mounted() {
    if (!this.advList || this.advList.length == 0) {
      this.getAdvList([res => {
        this.images = [];
        res.forEach((item, index) => {
          this.images.push({
            url: item.image,
            link_type: item.link_type,
            link: item.link
          });
        });
      }]);
    }
    if (this.categoryList.length == 0) {
      await this.getCategoryList();
      if (this.categoryList.length > 0) {
        this.setSelectTab(this.categoryList[1].id);
        this.selectCategory = this.categoryList.find(item => {
          return item.id == this.categoryList[0].id;
        });
        if (!this.taskList[this.selectTab] || this.taskList[this.selectTab].length == 0) {
          this.$loading.show();
          await this.getTaskList([this.selectTab, data => {
            this.$loading.hide();
          }, () => {
            this.$loading.hide();
          }]);
        }
      }
    } else {
      if (!this.selectTab) {
        this.setSelectTab(this.categoryList[0].id);
      }
      this.selectCategory = this.categoryList.find(item => {
        return item.id == this.selectTab;
      });
    }
  },
  methods: {
    ...appcenterVuex.mapActions(["getCategoryList", "getTaskList", "getAdvList", "taskUpgrade", "getTaskInfo"]),
    ...appcenterVuex.mapMutations(['setSelectTab', 'setCategoryList', 'setTaskList', "updateTask"]),
    ...userVuex.mapMutations(['setUserData']),
    async onBoo(item) {
      if (item.status == 0) {
        return;
      }
      if (item.type == 'miniapp') {
        await this.getTaskInfo([item.id]);
        this.popEnergyShow = true;
      } else if (item.type == 'page') {
        this.$router.push(item.url);
      }
    },
    async click_tab(item) {
      if (item.status == 0) {
        return;
      }
      let type = item.id;
      this.setSelectTab(type);
      this.selectCategory = this.categoryList.find(item => {
        return item.id == type;
      });
      this.$loading.show(this.selectCategory);
      document.getElementById("appcenter").scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth' // 可选，平滑滚动
      });

      if (!this.taskList[type] || this.taskList[type].length == 0) {
        await this.getTaskList([type, data => {
          this.$loading.hide();
        }, () => {
          this.$loading.hide();
        }]);
      } else {
        this.$loading.hide();
      }
    },
    async refreshData() {
      let newUserData = {};
      newUserData.app_center_tickets = this.userData.app_center_tickets + 1;
      this.setUserData(newUserData);
    },
    showTip() {
      this.popEngrayTipShow = true;
    }
  }
};