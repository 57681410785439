import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const taskVuex = createNamespacedHelpers("task");
const userVuex = createNamespacedHelpers("user");
import { address, toNano } from "@ton/core";
import cookie from "js-cookie";
export default {
  name: "popAirdripTip",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting", "tonConnect"]),
    ...userVuex.mapState(["userData", "token"]),
    ...taskVuex.mapState(["prepare"])
  },
  watch: {
    "$parent.popEarnTipShow": {
      handler(bool) {
        if (bool) {
          this.itemData = this.$parent.itemData;
          if (!this.itemData.check_countdown) {
            this.itemData.check_countdown = this.itemData.verifiable ? 5 : 15;
          }
          if (!this.itemData.check_countdown_prompt) {
            this.itemData.check_countdown_prompt = this.$lang('Mission not completed');
          }
          this.leftTime = 0;
          this.checkNum = 0;
          this.isCheck = false;
          this.cacheKey = `${this.setting.appname}_TaskClick_${this.userData.id}_${this.itemData.id}}`;
          this.isClick = cookie.get(this.cacheKey) ? true : false;
          this.paymentLoading = false;
          this.errorTip = false;
          if (this.itemData.type == 'payment') {
            this.setPrepare(null);
            this.isClick = false;
          }
        } else {
          clearTimeout(this.timeVal);
        }
      }
    }
  },
  data() {
    return {
      itemData: null,
      timeVal: "",
      leftTime: 0,
      checkNum: 0,
      isCheck: false,
      cacheKey: "",
      isClick: false,
      paymentLoading: false,
      errorTip: false
    };
  },
  methods: {
    ...walletVuex.mapActions(["loadTaskCount"]),
    ...taskVuex.mapActions(["getSubTaskList", "taskClick", "doSubTask", "getPrepare"]),
    ...taskVuex.mapMutations(['setPrepare']),
    ...userVuex.mapMutations(['setUserData']),
    async onClick() {
      if (this.itemData.type == 'telegram' || this.itemData.type == 'channel') {
        this.WebApp.openTelegramLink(this.itemData.url);
      } else if ((this.itemData.type || '') == 'advPlatForm' || (this.itemData.provider || '') == 'advPlatForm') {
        this.WebApp.openTelegramLink(`${this.setting.advapp}?startapp=${this.token}=${this.itemData.id}-${this.itemData.adv}`);
        this.WebApp.close();
      } else if (this.itemData.type == 'payment') {
        if (this.paymentLoading == true) {
          return;
        }
        this.paymentLoading = true;
        if (!this.tonConnect) {
          this.$nextTick(() => {
            this.tonWallet.openOrCloseTonModal(true);
            this.tonWallet.ton.onStatusChange(async walletAndwalletInfo => {
              if (walletAndwalletInfo) {
                await this.sendTrans();
              } else {
                this.paymentLoading = false;
              }
            });
          });
        } else {
          await this.sendTrans();
        }
        return;
      } else {
        this.WebApp.openLink(this.itemData.url);
      }
      if (this.itemData.completed) {
        this.$parent.popEarnTipShow = false;
      } else {
        this.isClick = true;
        //this.isCheck = true
        this.leftTime = this.itemData.check_countdown;
        this.countDown();
      }
    },
    async sendTrans() {
      await this.getPrepare([this.itemData.id, this.itemData.type, '', this.tonWallet.uiAddress, rs => {
        if (this.prepare.chainInfo) {
          this.tonWallet.sendTransaction([{
            address: this.prepare.chainInfo.contract,
            amount: toNano(this.prepare.chainInfo.amount).toString(),
            payload: this.prepare.chainInfo.raw
          }]);
        } else if (this.prepare) {
          this.$toasted.success('You have enough balance to make the payment, please click check');
        }
        this.isClick = true;
        this.paymentLoading = false;
      }, rs => {}]);
    },
    async onCheck() {
      if (this.itemData.completed) {
        this.$parent.popEarnTipShow = false;
      } else {
        this.isCheck = true;
        // if(this.itemData.verifiable && this.)
        //   this.leftTime = 5;
        // else
        // {
        //   if(this.itemData.type == 'payment'){
        //     if(!this.prepare){
        //       this.isCheck = false
        //       this.isClick = false
        //       return;
        //     }
        //   }
        //   this.leftTime = 20;
        // }
        // this.countDown();
        setTimeout(() => {
          if (this.leftTime <= 0) {
            this.onExtralJoin(this.itemData, this.itemData.adv || 0);
          } else {
            this.isCheck = false;
            this.errorTip = true;
          }
        }, 2 * 1000);
      }
    },
    countDown() {
      clearTimeout(this.timeVal);
      this.leftTime--;
      if (this.leftTime > 0) {
        this.timeVal = setTimeout(() => {
          this.countDown();
        }, 1000);
      } else {
        let expires = new Date(new Date().getTime() + 120 * 1000);
        cookie.set(this.cacheKey, "1", {
          expires: expires
        });
        //this.onExtralJoin(this.itemData,this.itemData.adv || 0)
      }
    },

    async onExtralJoin(item, adv = 0) {
      console.log("url=====", item.url);
      this.checkNum = 0;
      if (!item.completed) {
        this.checkExtralTask(item, adv);
      } else {
        this.$parent.popEarnTipShow = false;
      }
    },
    async checkExtralTask(item, adv) {
      clearTimeout(this.timeVal);
      if (this.checkNum >= 2) {
        this.isCheck = false;
        this.checkNum = 0;
        return;
      }
      this.checkNum++;
      let rs = await this.$http.post("/task/complete", {
        taskId: item.id,
        adv: adv,
        kind: this.itemData.kind || null,
        address: this.tonConnect && this.tonWallet.uiAddress || null,
        value: this.prepare && this.prepare.paymentId || 0
      });
      if (rs.code == 0) {
        item.completed = 1;
        if (item.cyclical == 1) {
          item.countdown = item.cycle_interval;
        }
        let newUserData = {
          ...this.userData,
          ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
        this.isCheck = false;
        this.$parent.popEarnTipShow = false;
        if (item.reward) this.$parent.isPopRewardResultShow = true;else this.$parent.onAccept();
        if (adv == 0) this.loadTaskCount();else await this.$parent.getAdvList();
      } else if (rs.code == 302) {
        this.timeVal = setTimeout(() => {
          this.checkExtralTask(item, adv);
        }, 2000);
      } else {
        this.isCheck = false;
      }
    }
  }
};