var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "100%",
      "height": "auto",
      "is-loc": "bottom"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main popLockAvatar"
  }, [_c('div', {
    staticClass: "avatar"
  }, [_c('div', {
    staticClass: "avatar-main"
  }, [_c('div', {
    staticClass: "avatar__img"
  }, [_c('img', {
    attrs: {
      "src": `${_vm.$parent.avatarUrl}/avatar-${_vm.$parent.selectLockRow}.png`,
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "avatar__name"
  }, [_vm._v("Title")])]), _c('div', {
    staticClass: "popLockAvatar__info"
  }, [_vm._v(" Each box has a large amount of Tapcoins,"), _c('br'), _vm._v(" keys and TAPS ")]), _c('div', {
    staticClass: "popLockAvatar__coin"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/tapcoins.png`),
      "alt": ""
    }
  }), _c('svg-icon', {
    attrs: {
      "name": "icon-close"
    }
  }), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/tap.png`),
      "alt": ""
    }
  }), _c('svg-icon', {
    attrs: {
      "name": "icon-close"
    }
  }), _c('img', {
    staticClass: "key",
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/key.png`),
      "alt": ""
    }
  })], 1), _c('div', {
    staticClass: "popLockAvatar-list"
  }, _vm._l(3, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "popLockAvatar-item",
      class: [_vm.selectGift === index && 'active', _vm.isLoading && _vm.selectGift !== index && 'disable'],
      on: {
        "click": function ($event) {
          !_vm.isLoading && _vm.onSelectGift(item, index);
        }
      }
    }, [_c('div', {
      staticClass: "popLockAvatar-item__bg",
      style: {
        background: 'url(' + require(`@images/${_vm.theme}/new/lock/avatar-lock-bg.jpg`) + ') center/auto 100% no-repeat'
      }
    }), _c('div', {
      staticClass: "popLockAvatar-item__li",
      style: {
        background: 'url(' + require(`@images/${_vm.theme}/new/lock/avatar-lock-l.png`) + ') center/auto 100% no-repeat'
      }
    }), _c('div', {
      staticClass: "popLockAvatar-item__star"
    }, _vm._l(index + 2, function (_) {
      return _c('span', {
        style: {
          background: 'url(' + require(`@images/${_vm.theme}/new/lock/avatar-lock-s.png`) + ') center/auto 100% no-repeat'
        }
      });
    }), 0), _c('div', {
      staticClass: "popLockAvatar-item__gift"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/${_vm.theme}/new/lock/gift-${index + 1}.png`),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "popLockAvatar-active"
    })]);
  }), 0), _c('div', {
    staticClass: "popLockAvatar-btns"
  }, [_vm.isLoading ? _c('div', {
    staticClass: "popLockAvatar-progress",
    class: [_vm.selectGift === 0 && 'gift1', _vm.selectGift === 1 && 'gift2', _vm.selectGift === 2 && 'gift3']
  }) : !_vm.isLoading ? _c('div', {
    staticClass: "btn-go",
    on: {
      "click": _vm.onAhead
    }
  }, [_vm._v(_vm._s(_vm.$lang("Go ahead")))]) : _vm._e()]), !_vm.isLoading ? _c('div', {
    staticClass: "popLockAvatar__need"
  }, [_vm._v("Need to spend: "), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/lucky/ton.png`),
      "alt": ""
    }
  }), _vm._v(" 0.7")]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };