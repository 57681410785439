import React from 'react'
import ReactDOM from 'react-dom'
import {
  getAd,
  getExchangeAd,
  getMultiAd,
  getMultiExchangeAd,
  postAdEvent,
  postExchangeEvent,
  postUserProfile,
  postCommonEvent,
  verifyExchangeAd,
  verifyAd
} from './api'

import {
  getTgWebAppData,
  getTgWebAppStartParam,
  monitorTgWebAppData,
  monitorUtmContentParam
} from './tg_utils'
import { listenForTelegramWebApp } from './telegramWebAppListener'
import {
  Destination,
  DeviceInfo,
  OnAdVerifyOptions,
  OnExchangeAdVerifyOptions,
  TonAdProps,
  TonExchangeAdProps
} from './models'
import { MD5 } from 'crypto-js'

// --- 常用的基础函数 ---

export const RenderWeidget = (
  containerId: string,
  weidget: any,
  param: any
) => {
  if (!window || !document) {
    console.log('window or document is not defined')
    return
  }
  const container = document.getElementById(containerId)
  if (!container) {
    console.error(`Container with id ${containerId} not found`)
    return
  }
  try {
    ReactDOM.render(React.createElement(weidget, param), container)
  } catch (e) {
    console.error('renderWeidget error:', e)
  }
}

const getTargetUrl = (destination: Destination) => {
  switch (destination?.actionType) {
    case 'join.telegram':
      return destination.destinationConfig.telegramLink
    case 'visit.website':
    default:
      return destination.destinationConfig.url
  }
}
export const openUrl = (destination: Destination) => {
  const url = getTargetUrl(destination)
  if (!url) {
    return
  }
  if ((window as any)?.openUrlFunc) {
    try {
      ;(window as any)?.openUrlFunc(url)
    } catch (error) {
      console.error('Failed to execute openUrlFunc:', error)
      window.open(url, '_blank')
    }
    return
  }

  // 打开 TG 链接
  const tgWebApp = (window as any)?.Telegram?.WebApp
  if (tgWebApp && tgWebApp?.initData) {
    // openLink：
    //   用于打开任意外部链接。
    //   示例用途：打开普通网页。
    // openTelegramLink：
    //   用于打开 Telegram 内部链接，包括用户、群组、频道和其他 mini app。
    //   示例用途：在 mini app 中打开另一个 mini app 或者 t.me/share/url 链接。
    // if (url?.match(/^https:\/\/t\.me\/[^/]+bot\/?(\?start=.*)?$/i)) {
    //   window.open(url, '_blank')
    //   return
    // }
    if (
      url?.includes('t.me/')
      // &&!url?.match(/^https:\/\/t\.me\/[^/]+bot\/?(\?start=.*)?$/i)
    ) {
      tgWebApp?.openTelegramLink(url)
    } else {
      tgWebApp?.openLink(url)
    }
    // }
  } else {
    window.open(url, '_blank')
  }
}
// 更新用户画像
export const UpdateUserProfile = async (
  params: {
    walletClassify?: 'ton' | 'evm'
    walletAddress?: string
    openId?: string
    events?: string[]
  },
  retry = false
) => {
  // console.log('UpdateUserProfile', { params, retry })
  if (!window) {
    SendTonCommonEvent('error', 'Please run in browser environment!')
    return { success: false, message: 'Please run in browser environment!' }
  }
  if (
    !localStorage.getItem('TON_AI_APP_KEY') &&
    !localStorage.getItem('TON_AI_APP_ID')
  ) {
    if (retry) {
      setTimeout(() => {
        UpdateUserProfile(params, false)
      }, 3000)
    }
    return { success: false, message: 'Please init SDK first!' }
  }
  if (!params.openId && !GetUserOpenId()) {
    if (retry) {
      setTimeout(() => {
        UpdateUserProfile(params, false)
      }, 3000)
    }
    SendTonCommonEvent(
      'error',
      'UpdateUserProfile: telegramUserId is required!'
    )
    return { success: false, message: 'telegramUserId is required!' }
  }
  try {
    if (!localStorage.getItem('TON_AI_DEVICE_INFO')) {
      console.log('UpdateUserProfile: DeviceInfo is not ready, wait 3s')
      await new Promise((resolve) => setTimeout(resolve, 1500))
    }
    const deviceInfo = JSON.parse(
      localStorage.getItem('TON_AI_DEVICE_INFO') || '{}'
    )
    let _params = {
      ...params,
      telegramMiniAppInitData: getTgWebAppData(),
      openId: params.openId || GetUserOpenId() || '',
      type: GetUserType() || 'telegram',
      uuid: GetUUID(),
      tgWebAppStartParam: getTgWebAppStartParam() || '',
      ...deviceInfo
    }
    const data = await postUserProfile(_params)
    return { success: true, data }
  } catch (e) {
    console.error('updateUserProfile', e)
    if (retry) {
      setTimeout(() => {
        UpdateUserProfile(params, false)
      }, 3000)
    }
    return { success: false, message: e.message }
  }
}
const InitDeviceInfo = (onCallBack: (deviceInfo: DeviceInfo) => void) => {
  let deviceInfo = localStorage.getItem('TON_AI_DEVICE_INFO')
    ? JSON.parse(localStorage.getItem('TON_AI_DEVICE_INFO') || '{}')
    : null
  if (deviceInfo?.deviceId) {
    onCallBack(deviceInfo)
  }
  try {
    if (!window || !document) {
      SendTonCommonEvent(
        'error',
        'InitDeviceInfo:Please run in browser environment!'
      )
      throw new Error('Please run in browser environment!')
    }
    loadScript(
      'https://cdn.jsdelivr.net/npm/clientjs@0.1.11/dist/client.min.js'
    )
      .then(() => {
        const client = new (window as any).ClientJS()
        const deviceId = client?.getFingerprint().toString()
        if (deviceId !== deviceInfo?.deviceId) {
          onCallBack(deviceInfo)
          localStorage.setItem('TON_AI_DEVICE_ID', deviceId!)
          deviceInfo = {
            currentResolution: client?.getCurrentResolution(),
            timeZone: client?.getTimeZone(),
            deviceId: deviceId
          }
          localStorage.setItem('TON_AI_DEVICE_INFO', JSON.stringify(deviceInfo))
        }
      })
      .catch((err) => {
        SendTonCommonEvent(
          'error',
          'InitDeviceInfo: Failed to load clientjs,' + err?.message
        )
        console.error('Failed to load clientjs:', err)
      })
  } catch (error) {
    SendTonCommonEvent('error', 'InitDeviceInfo error:' + error?.message)
    console.error('GetDeviceId error:', error)
    // onCallBack(deviceInfo)
  }
}

// 获取 TG 用户信息
export const GetTelegramUserInfo = () => {
  return getTgWebAppData()?.user
}
export const GetUserOpenId = () => {
  const openUserId = localStorage?.getItem('TON_AI_OPEN_USER_ID')
  if (openUserId && openUserId !== 'undefined' && openUserId !== 'null') {
    return openUserId?.toString()
  }
  return getTgWebAppData()?.user?.id?.toString() || null
}
// 初始化
export const TonAdInit = (params: {
  appId?: string
  appKey?: string
  openId?: string
  disableDefaultConversion?: boolean
  debug?: boolean
  baseUrl?: string
  disableTgJs?: boolean
  OpenUrlFunc?: (url: string) => void
}) => {
  if (!window || !document) {
    // 必须在能拿到 window 对象的环境下运行
    SendTonCommonEvent('error', 'TonAdInit: Please run in browser environment!')
    return {
      success: false,
      message:
        'Unable to access the window object. Please run this in a browser.'
    }
  }
  const { appKey, appId, baseUrl, debug, openId, disableDefaultConversion } =
    params
  console.log('TonAdInit:', params)
  if (!appKey && !appId) {
    SendTonCommonEvent('error', 'TonAdInit: App key or App id is required!')
    console.error('TonAdInit: App key or App id is required!')
    return { success: false, message: 'App key or App id is required!' }
  }
  if (appKey) {
    localStorage.setItem('TON_AI_APP_KEY', appKey)
  } else {
    localStorage.removeItem('TON_AI_APP_KEY')
  }
  if (appId) {
    localStorage.setItem('TON_AI_APP_ID', appId)
  } else {
    localStorage.removeItem('TON_AI_APP_ID')
  }
  if (baseUrl && baseUrl.includes('ton.ai')) {
    // 设置 baseURL
    localStorage.setItem('TON_AI_BASE_URL', baseUrl)
  } else {
    localStorage.removeItem('TON_AI_BASE_URL')
  }
  if (debug) {
    localStorage.setItem('TON_AI_DEBUG', 'true')
  } else {
    localStorage.removeItem('TON_AI_DEBUG')
  }
  if (openId && openId !== 'undefined' && openId !== 'null') {
    localStorage.setItem('TON_AI_OPEN_USER_ID', openId?.toString())
  }

  if (params?.OpenUrlFunc) {
    ;(window as any).openUrlFunc = params.OpenUrlFunc
  } else {
    ;(window as any).openUrlFunc = null
  }

  initTgJS(params?.disableTgJs)
  // 监听 tgWebAppData 变化, 用于获取TG信息
  monitorTgWebAppData()
  monitorUtmContentParam()
  // 可以先不需要监听 tgWebAppStartParam，因为 tgWebAppData 已经包含 startParam
  // monitorTgWebAppStartParam()
  // 先上报 onBoard 事件
  // UpdateUserProfile({ events: ['onBoard'] })
  // 获取设备 ID
  InitDeviceInfo((deviceInfo) => {
    UpdateUserProfile({ events: ['onBoard'] })
      .then((res) => {
        console.log('TonAdInit onBoard', res)
      })
      .catch((e) => {
        SendTonCommonEvent('error', 'TonAdInit onBoard error:' + e?.message)
        console.error('TonAdInit onBoard', e)
      })
  })

  if (!disableDefaultConversion) {
    SendTonAdConversion()
    // SendTonExchangeConversion()
  }
  return { init: 'Success' }
}

// 设置用户 openId
export const SetUserOpenId = (openId: string) => {
  if (openId && openId !== 'undefined' && openId !== 'null') {
    localStorage?.setItem('TON_AI_OPEN_USER_ID', openId?.toString())
    localStorage?.setItem('TON_AI_USER_TYPE', 'telegram')
  }
}

export const SetWebUserOpenId = (openId: string) => {
  if (openId && openId !== 'undefined' && openId !== 'null') {
    localStorage?.setItem('TON_AI_OPEN_USER_ID', openId?.toString())
    localStorage?.setItem('TON_AI_USER_TYPE', 'web')
  }
}

export const SetDiscordUserOpenId = (openId: string) => {
  if (openId && openId !== 'undefined' && openId !== 'null') {
    localStorage?.setItem('TON_AI_OPEN_USER_ID', openId?.toString())
    localStorage?.setItem('TON_AI_USER_TYPE', 'discord')
  }
}

const saveTgData = (tgData: any) => {
  // console.log('initTgJS Telegram.WebApp:\n', tgData)
  if (tgData) {
    if (tgData?.user?.id) {
      // localStorage?.setItem('tgWebAppData', JSON.stringify(tgData))
      localStorage?.setItem('TON_AI_TG_APP_DATA', JSON.stringify(tgData))
      if (tgData?.user?.id !== localStorage?.getItem('TON_AI_OPEN_USER_ID')) {
        localStorage?.setItem(
          'TON_AI_OPEN_USER_ID',
          tgData?.user?.id?.toString()
        )
      }
    }
    if (tgData?.start_param) {
      localStorage?.setItem('TON_AI_TG_START_PARAM', tgData?.start_param)
    }
  }
}

const initTgJS = async (disableTgJs?: boolean) => {
  try {
    // 监听 Window.Telegram.WebApp 对象，双重保险
    listenForTelegramWebApp(
      saveTgData,
      // 如果 TG 对象不存在，则加载 TG 脚本
      async () => {
        if (!(window as any)?.Telegram?.WebApp && !disableTgJs) {
          console.log('initTgJS load telegram script')
          await loadScript('https://telegram.org/js/telegram-web-app.js')
        }
        // 再次监听 TG 对象
        listenForTelegramWebApp(saveTgData)
      }
    )
  } catch (error) {
    console.error('initTgJS load telegram script error:', error)
  }
}

export function loadScript(src: string) {
  if (!document) {
    SendTonCommonEvent(
      'error',
      'loadScript: Please run in browser environment!'
    )
    return Promise.reject(new Error('Please run in browser environment!'))
  }
  return new Promise<void>((resolve, reject) => {
    // 检查脚本是否已经加载
    if (document.querySelector(`script[src="${src}"]`)) {
      console.log(`loadScript ${src} already loaded`)
      resolve()
      return
    }

    const script = document.createElement('script')
    script.src = src
    script.async = true

    script.onload = () => {
      console.log(`loadScript ${src} success`)
      resolve()
    }

    script.onerror = () => {
      SendTonCommonEvent('error', 'loadScript: Failed to load script')
      console.error(`loadScript ${src} failed`)
      reject(new Error(`loadScript ${src} failed`))
    }
    document.head.appendChild(script)
  })
}

/**
 * 发送通用事件到 TON AI 平台
 * @param eventName - 事件名称
 * @param openId - Telegram 用户 ID
 * @param eventSource - 事件来源，可选值：'sdk' | 'api' | 'web'，默认为 'sdk'
 * @returns Promise<boolean> - 成功返回 true，失败返回 false
 */
export const SendCommonEvent = async (
  eventName: string,
  openId: string,
  type?: 'telegram'
): Promise<{ success: boolean; message?: string }> => {
  if (
    !localStorage.getItem('TON_AI_APP_KEY') &&
    !localStorage.getItem('TON_AI_APP_ID')
  ) {
    console.error('SendCommonEvent: Please init SDK first!')
    return { success: false, message: 'Please init SDK first!' }
  }
  try {
    if (!eventName || !openId) {
      console.error('SendCommonEvent: eventName and openId are required!')
      return { success: false, message: 'eventName and openId are required!' }
    }
    if (!type) {
      type = 'telegram'
    }
    await postCommonEvent({
      eventName,
      openId: openId?.toString(),
      type: 'telegram',
      eventSource: 'sdk'
    })

    return { success: true, message: 'Event sent successfully' }
  } catch (error) {
    console.error('SendCommonEvent failed:', error)
    return {
      success: false,
      message: error?.message || 'SendCommonEvent failed'
    }
  }
}

// --- 换量广告相关函数 ---

export const GetTonExchangeAd = async (exchangeId: string) => {
  try {
    const data = await getExchangeAd({
      exchangeCampaignId: exchangeId,
      source: 'earn',
      viewer: {
        telegramUserId: GetUserOpenId() || ''
      }
    })

    if (data.success) {
      return {
        success: true,
        data: {
          ...data,
          url: getTargetUrl(data.destination)
        }
      }
    } else {
      SendTonCommonEvent(
        'error',
        'GetTonExchangeAd error:' + data.message || data.msg || 'Fetch Ad error'
      )
      return {
        success: false,
        message: data.message || data.msg || 'Fetch Ad error'
      }
    }
  } catch (e) {
    console.error('fetch ad', e)
    SendTonCommonEvent('error', 'GetTonExchangeAd error:' + e?.message)
    return { success: false, message: e.message }
  }
}

export const GetMultiTonExchangeAd = async (exchangeId: string, limit = 1) => {
  try {
    const data = await getMultiExchangeAd({
      exchangeCampaignId: exchangeId,
      source: 'earn',
      limit,
      viewer: { telegramUserId: GetUserOpenId() || '' }
    })

    if (data.success) {
      return {
        success: true,
        ads: data.exchangeCampaigns.map((ad: any) => {
          return {
            ...ad,
            url: getTargetUrl(ad.destination)
          }
        })
      }
    } else {
      SendTonCommonEvent(
        'error',
        'GetMultiTonExchangeAd error:' + data.message ||
          data.msg ||
          'Fetch Ad error'
      )
      return {
        success: false,
        message: data.message || data.msg || 'Fetch Ad error'
      }
    }
  } catch (e) {
    console.error('fetch ad', e)
    SendTonCommonEvent('error', 'GetMultiTonExchangeAd error:' + e?.message)
    return { success: false, message: e.message }
  }
}

export const SendTonExchangeClickEvent = async (ad: {}) => {
  if (!window) {
    SendTonCommonEvent(
      'error',
      'SendTonExchangeClickEvent: Please run in browser environment!'
    )
    return { success: false, message: 'Please run in browser environment!' }
  }
  if (!ad) {
    SendTonCommonEvent('error', 'SendTonExchangeClickEvent: Ad is required!')
    return { success: false, message: 'Ad is required!' }
  }
  if (
    !localStorage.getItem('TON_AI_APP_KEY') &&
    !localStorage.getItem('TON_AI_APP_ID')
  ) {
    SendTonCommonEvent(
      'error',
      'SendTonExchangeClickEvent: Please init SDK first!'
    )
    return { success: false, message: 'Please init SDK first!' }
  }
  SendTonExchangeEvent(
    'click',
    ad,
    () => {
      return { success: true }
    },
    (e) => {
      return { success: false, message: e?.message }
    }
  )
}

export const SendTonExchangeConversion = async (
  // exchangeId: string,
  telegramUserId?: string
) => {
  if (!window) {
    SendTonCommonEvent(
      'error',
      'SendTonExchangeConversion: Please run in browser environment!'
    )
    return { success: false, message: 'Please run in browser environment!' }
  }
  if (
    !localStorage.getItem('TON_AI_APP_KEY') &&
    !localStorage.getItem('TON_AI_APP_ID')
  ) {
    SendTonCommonEvent(
      'error',
      'SendTonExchangeConversion: Please init SDK first!'
    )
    return { success: false, message: 'Please init SDK first!' }
  }
  SendTonExchangeEvent(
    'conversion',
    { telegramUserId },
    () => {
      return { success: true }
    },
    (e) => {
      return { success: false, message: e?.message }
    }
  )
}

// 用户点击换量广告
export const OnExchangeAdClick = async (
  ad: TonExchangeAdProps,
  onVerifySuccess: (options: OnExchangeAdVerifyOptions) => void,
  openId?: string
) => {
  openUrl(ad.destination)
  OnExchangeAdVerified({
    ad,
    onVerifySuccess,
    openId,
    times: 0
  })
}

// 用户点击广告
export const OnAdClick = async (
  ad: TonAdProps,
  onVerifySuccess: (options: OnAdVerifyOptions) => void,
  openId?: string
) => {
  openUrl(ad.destination)
  OnAdVerified({
    ad,
    onVerifySuccess,
    openId,
    times: 0
  })
}

// 验证换量广告是否完成
export async function OnExchangeAdVerified(parms: {
  ad: TonExchangeAdProps
  onVerifySuccess: (options: OnExchangeAdVerifyOptions) => void
  openId?: string
  times?: number
}) {
  const { ad, onVerifySuccess, times } = parms
  const _openId = parms.openId || GetUserOpenId() || ''
  const _times = times || 0
  if (ad.isSupportVerified) {
    // 强验证，先发送点击事件
    if (_times === 0) {
      await SendTonExchangeClickEvent(ad)
    }
    // 如果广告支持验证，则需要验证广告是否完成
    if (_times >= 3) {
      // 如果验证次数超过3次，则认为验证失败
      // console.warn('verifyExchangeAd times more than 3')
      SendTonCommonEvent('error', 'verifyExchangeAd times more than 3')
      onVerifySuccess?.({
        success: false,
        ad,
        message: 'Verify timeout!'
      })
      return
    }
    setTimeout(
      () => {
        verifyExchangeAd({
          exchangeCampaignId: ad.inExchangeCampaignId,
          openId: _openId
        })
          .then((res: { isVerified: boolean; message: string }) => {
            if (res.isVerified) {
              onVerifySuccess?.({
                success: true,
                ad
              })
            } else {
              console.warn('verifyExchangeAd', res.message)
              OnExchangeAdVerified({
                ad,
                onVerifySuccess,
                openId: _openId,
                times: times + 1
              })
            }
          })
          .catch((e) => {
            console.error('verifyExchangeAd', e)
            SendTonCommonEvent('error', 'verifyExchangeAd error:' + e?.message)
            OnExchangeAdVerified({
              ad,
              onVerifySuccess,
              openId: _openId,
              times: times + 1
            })
          })
      },
      times * 2000 + 2000
    )
  } else {
    // 如果广告不支持验证，则认为8秒后验证成功
    // console.log('ad not support verified, wait 8 seconds')
    setTimeout(async () => {
      await SendTonExchangeClickEvent(ad)
      onVerifySuccess?.({
        success: true,
        ad
      })
    }, 8000)
  }
}

// 验证广告是否完成
export async function OnAdVerified(parms: {
  ad: TonAdProps
  onVerifySuccess: (options: OnAdVerifyOptions) => void
  openId?: string
  times?: number
}) {
  const { ad, onVerifySuccess, times } = parms
  const _openId = parms.openId || GetUserOpenId() || ''
  const _times = times || 0
  if (ad.isSupportVerified) {
    // 强验证，先发送点击事件
    if (_times === 0) {
      await SendTonAdClickEvent(ad)
    }
    // 如果广告支持验证，则需要验证广告是否完成
    if (_times >= 3) {
      // 如果验证次数超过3次，则认为验证失败
      // console.warn('verifyExchangeAd times more than 3')
      SendTonCommonEvent('error', 'verifyAd times more than 3')
      onVerifySuccess?.({
        success: false,
        ad,
        message: 'Verify timeout!'
      })
      return
    }
    setTimeout(
      () => {
        verifyAd({
          campaignId: ad.campaignId,
          openId: _openId || GetUserOpenId() || '',
          type: GetUserType() || 'telegram',
          uuid: GetUUID()
        })
          .then((res: { isVerified: boolean; message: string }) => {
            if (res.isVerified) {
              onVerifySuccess?.({
                success: true,
                ad
              })
            } else {
              console.warn('verifyAd', res.message)
              OnAdVerified({
                ad,
                onVerifySuccess,
                openId: _openId,
                times: times + 1
              })
            }
          })
          .catch((e) => {
            SendTonCommonEvent('error', 'verifyAd error:' + e?.message)
            console.error('verifyAd', e)
            OnAdVerified({
              ad,
              onVerifySuccess,
              openId: _openId,
              times: times + 1
            })
          })
      },
      times * 2000 + 2000
    )
  } else {
    // 如果广告不支持验证，则认为8秒后验证成功
    // console.log('ad not support verified, wait 8 seconds')
    setTimeout(async () => {
      await SendTonAdClickEvent(ad)
      onVerifySuccess?.({
        success: true,
        ad
      })
    }, 8000)
  }
}

// --- 广告相关函数 ---
export const GetMultiTonAd = async (
  adBlockId: string,
  limit = 1
): Promise<{
  success: boolean
  ads?: TonAdProps[]
  message?: string
}> => {
  try {
    const data = await getMultiAd({
      adBlockId: adBlockId,
      limit,
      viewer: {
        openId: GetUserOpenId() || '',
        uuid: GetUUID(),
        type: GetUserType()
      }
    })

    if (data.success) {
      return {
        success: true,
        ads: data.ads.map((ad: any) => {
          return {
            ...ad,
            url: getTargetUrl(ad.destination)
          }
        })
      }
    } else {
      SendTonCommonEvent(
        'error',
        'GetMultiTonAd error:' + data.message || data.msg || 'Fetch Ad error'
      )
      return {
        success: false,
        message: data.message || data.msg || 'Fetch Ad error'
      }
    }
  } catch (e) {
    SendTonCommonEvent('error', 'GetMultiTonAd error:' + e?.message)
    console.error('fetch ad', e)
    return { success: false, message: e.message }
  }
}

export const GetTonAd = async (adBlockId: string) => {
  try {
    const data = await getAd({
      adBlockId: adBlockId,
      source: 'earn',
      viewer: {
        openId: GetUserOpenId() || '',
        uuid: GetUUID(),
        type: GetUserType()
      }
    })

    if (data.success) {
      return {
        success: true,
        data: {
          ...data,
          url: getTargetUrl(data.destination)
        }
      }
    } else {
      SendTonCommonEvent(
        'error',
        'GetTonAd error:' + data.message || data.msg || 'Fetch Ad error'
      )
      return {
        success: false,
        message: data.message || data.msg || 'Fetch Ad error'
      }
    }
  } catch (e) {
    SendTonCommonEvent('error', 'GetTonAd error:' + e?.message)
    console.error('fetch ad', e)
    return { success: false, message: e.message }
  }
}

// 生成盐值
const generateSalt = () => {
  const baseValue = 'Ton.AI'
  const charCodes = baseValue.split('').map((char) => char.charCodeAt(0))
  const salt = charCodes.reduce(
    (acc, code) => acc + (code * 3).toString(16),
    ''
  )
  return salt
}

// 生成签名
export const signRequest = (requestBody?: {
  eventData: Object
  eventType: string
}) => {
  if (!requestBody) {
    return ''
  }
  const appId =
    localStorage.getItem('TON_AI_APP_ID') ||
    localStorage.getItem('TON_AI_APP_KEY')
  if (!appId) {
    throw new Error('Please init SDK first!')
  }
  // 生成盐值
  const salt = generateSalt()
  const params = requestBody
  const data = {
    ...params.eventData
  } as any
  // 排序
  let dataCheckString = Object.keys(data)
    .sort()
    .map((key) => `${key}=${data[key]}`)
    .join('&')
  dataCheckString += `&appKey=${appId}&salt=${salt}`
  // MD5 加密
  const sign = MD5(dataCheckString)?.toString()?.toLowerCase()
  // console.log('signRequest', { dataCheckString, sign })
  return sign
}

export const SendTonAdClickEvent = async (ad: {}) => {
  if (!window) {
    return { success: false, message: 'Please run in browser environment!' }
  }
  if (!ad) {
    return { success: false, message: 'Ad is required!' }
  }
  if (
    !localStorage.getItem('TON_AI_APP_KEY') &&
    !localStorage.getItem('TON_AI_APP_ID')
  ) {
    return { success: false, message: 'Please init SDK first!' }
  }
  SendTonAdEvent(
    'click',
    ad,
    () => {
      return { success: true }
    },
    (e) => {
      SendTonCommonEvent('error', 'SendTonAdClickEvent error:' + e?.message)
      return { success: false, message: e?.message }
    }
  )
}

export const SendTonAdConversion = async (telegramUserId?: string) => {
  if (!window) {
    return { success: false, message: 'Please run in browser environment!' }
  }
  if (
    !localStorage.getItem('TON_AI_APP_KEY') &&
    !localStorage.getItem('TON_AI_APP_ID')
  ) {
    return { success: false, message: 'Please init SDK first!' }
  }
  SendTonAdEvent(
    'conversion',
    { telegramUserId: telegramUserId || GetUserOpenId() || '' },
    () => {
      return { success: true }
    },
    (e) => {
      SendTonCommonEvent('error', 'SendTonAdConversion error:' + e?.message)
      return { success: false, message: e?.message }
    }
  )
}

const GetUtmContent = () => {
  const utm_content = localStorage?.getItem('TON_AI_UTM_CONTENT')
  if (utm_content?.includes('_')) {
    const _utm_content = utm_content?.split('_')
    return {
      openId: _utm_content[0],
      uuid: _utm_content[1]
    }
  }
  return {}
}

export const SendTonAdEvent = async (
  eventType: 'impression' | 'click' | 'conversion' | 'complete',
  ad: {
    adId?: string
    adBlockId?: string
    campaignId?: string
    telegramUserId?: string
    num?: number
  },
  onSuccess?: (params: any) => void,
  onError?: (e: any) => void
) => {
  // 如果用户没有telegramUserId，则等待3秒后再次尝试获取
  if (!ad.telegramUserId && !GetUserOpenId() && !GetUtmContent()?.openId) {
    await new Promise((resolve) => setTimeout(resolve, 3000))
  }
  // 如果用户还是没有telegramUserId，则等待5秒后再次尝试获取
  if (!ad.telegramUserId && !GetUserOpenId() && !GetUtmContent()?.openId) {
    await new Promise((resolve) => setTimeout(resolve, 5000))
  }
  if (!ad.telegramUserId && !GetUserOpenId() && !GetUtmContent()?.openId) {
    SendTonCommonEvent('error', 'SendTonAdEvent:User open id not found!')
    onError?.('User open id not found!')
    return
  }
  // 如果是 conversion 事件，则使用 utm_content 的 openId 和 uuid （主要是 web 流量）
  let utmContent = null
  if (eventType === 'conversion') {
    utmContent = GetUtmContent()
  }
  const eventData = {
    adId: ad?.adId,
    adBlockId: ad?.adBlockId,
    campaignId: ad?.campaignId,
    type: GetUserType() || 'telegram',
    openId: utmContent?.openId || ad?.telegramUserId || GetUserOpenId() || '',
    uuid: utmContent?.uuid || GetUUID()
  }
  if (eventType === 'impression' && ad?.num >= 0) {
    eventData['num'] = ad?.num
  }
  postAdEvent({
    eventType: eventType,
    eventData: eventData
  })
    .then((res) => {
      if (eventType === 'conversion' && utmContent?.openId) {
        // 如果事件是conversion，用完之后则移除 utm_content
        localStorage?.removeItem('TON_AI_UTM_CONTENT')
      }
      onSuccess?.(res)
    })
    .catch((e) => {
      SendTonCommonEvent('error', 'SendTonAdEvent error:' + e?.message)
      onError?.(e)
    })
}

export const SendTonExchangeEvent = async (
  eventType: 'click' | 'impression' | 'conversion' | 'complete',
  ad: {
    outExchangeCampaignId?: string
    inExchangeCampaignId?: string
    telegramUserId?: string
  },
  onSuccess?: (params: any) => void,
  onError?: (e: any) => void
) => {
  // 如果用户没有telegramUserId，则等待3秒后再次尝试获取
  if (!ad.telegramUserId && !GetUserOpenId()) {
    await new Promise((resolve) => setTimeout(resolve, 3000))
  }
  // 如果用户还是没有telegramUserId，则等待5秒后再次尝试获取
  if (!ad.telegramUserId && !GetUserOpenId()) {
    await new Promise((resolve) => setTimeout(resolve, 5000))
  }
  if (!ad.telegramUserId && !GetUserOpenId()) {
    SendTonCommonEvent('error', 'SendTonExchangeEvent:User open id not found!')
    onError?.('User open id not found!')
    return
  }
  postExchangeEvent({
    eventType: eventType,
    eventData: {
      outExchangeCampaignId: ad?.outExchangeCampaignId,
      inExchangeCampaignId: ad?.inExchangeCampaignId,
      telegramUserId: ad?.telegramUserId || GetUserOpenId() || ''
    }
  })
    .then((res) => {
      onSuccess?.(res)
    })
    .catch((e) => {
      SendTonCommonEvent('error', 'SendTonExchangeEvent error:' + e?.message)
      onError?.(e)
    })
}

export const SendTonCommonEvent = async (
  eventName: string,
  message?: string,
  type?: 'telegram' | 'web',
  openId?: string,
  onSuccess?: (params: any) => void,
  onError?: (e: any) => void
) => {
  // 如果没有初始化 SDK，则返回错误
  if (
    !localStorage.getItem('TON_AI_APP_KEY') &&
    !localStorage.getItem('TON_AI_APP_ID')
  ) {
    const error = 'Please init SDK first!'
    console.error('SendTonCommonEvent:', error)
    onError?.(error)
    return
  }

  postCommonEvent({
    eventName: eventName + (message ? `-[${message}]` : ''),
    openId: openId?.toString() || GetUserOpenId() || '',
    type: type || 'telegram',
    eventSource: 'sdk'
  })
    .then((res) => {
      onSuccess?.(res)
    })
    .catch((e) => {
      onError?.(e)
    })
}
// 获取设备ID
const GetUUID = () => {
  return GetDeviceId()
}
export const GetDeviceId = () => {
  let deviceId = localStorage.getItem('TON_AI_DEVICE_ID')
  if (!deviceId) {
    InitDeviceInfo((deviceInfo) => {
      deviceId = deviceInfo?.deviceId
      return deviceId?.toString()
    })
  }
  return deviceId?.toString()
}
// 获取用户类型
const GetUserType = () => {
  const type = localStorage.getItem('TON_AI_USER_TYPE')
  if (!type) {
    return 'telegram'
  }
  return type
}
