var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "width": "95%",
      "height": "auto",
      "is-loc": "center"
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "energy__img"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/airdrop-pass.png`)
    }
  })]), _c('div', {
    staticClass: "energy__title"
  }, [_vm._v(" AIRDROP-PASS ")]), _c('div', {
    staticClass: "energy__subtitle"
  }, [_vm._v(" You will get $TAPS based on the number of Airdrop-Pass the end of the airdrop ")]), _c('div', {
    staticClass: "energy__threetitle"
  }, [_c('div', {
    staticClass: "three-box"
  }, [_c('div', {
    staticClass: "start"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "txt"
  }, [_vm._v("Your Pass count is below most players. Focus on improving your indicators to secure Airdrop.")])])]), _c('div', {
    staticClass: "energy__threetitle"
  }, [_c('div', {
    staticClass: "three-box"
  }, [_c('div', {
    staticClass: "start"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  }), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "txt"
  }, [_vm._v("Your Pass count is average. Keep improving your indicators to claim a larger share of the Airdrop.")])])]), _c('div', {
    staticClass: "energy__threetitle"
  }, [_c('div', {
    staticClass: "three-box"
  }, [_c('div', {
    staticClass: "start"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  }), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  }), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.theme}/start.png`)
    }
  })]), _c('div', {
    staticClass: "txt"
  }, [_vm._v("Your Pass count is ahead of most players. Continue improving to maintain your lead and maximize rewards.")])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };